import React from 'react';
import Form from  '../components/Form';


export default function Enroll(props) {
    return <Form form={ FORMITEMLIST }/>
}

//立即报名
const FORMITEMLIST =  {
  type:'enroll',tabs:[
    {label:'立即报名',type:'enroll'},
    {label:'我的报名',type:'myEnroll'}
  ],
  className:'long-label',
  list:[
    {label:'工作单位',placeholder:'请填写工作单位',required:true,name:'companyName',type:'input',maxLength:100},
    {label:'姓名',placeholder:'请填写参赛者姓名',required:true,name:'contestantName',type:'input',maxLength:50},
    {label:'团队其他成员姓名',placeholder:'请填写团队其他成员姓名（非必填）',required:false,name:'teamMemberName',type:'input',maxLength:255},
    {label:'手机号码',placeholder:'请填写参赛者手机号码',required:true,name:'phone',valid:['mobile'],type:'input',type2:'number',maxLength:40},
    {label:'电子邮箱',placeholder:'请填写电子邮箱',name:'email',valid:['email'],required:true,type:'input',maxLength:50},
    {label:'微信号',placeholder:'请填写微信号',name:'wechatNo',required:true,type:'input',maxLength:50},
    {label:'RAYS编辑端账号',placeholder:'请填写RAYS编辑端账号',required:true,name:'adviserAccount',type:'input',maxLength:50},
    {label:'身份证号',placeholder:'请填写参赛者身份证号（非必填）',required:false,name:'idCard',valid:['idCard'],type:'input',maxLength:50},
    {label:'注册责任编辑/出版职业资格证书编号',placeholder:'请填写证书编号（非必填）',required:false,name:'adviserCertificateNo',type:'input',maxLength:100},
    {label:'通讯地址',placeholder:'请填写通讯地址（非必填）',required:false,name:'address',type:'input',maxLength:255},
    {label:'',margin:'205px'},
    {label:'下一步',name:'stepbtn',type:'button',type2:'nextStep',params:'enroll2'}
  ]
}